import { useUser } from '~/composables/data/useUser'
import { updateOrganization } from '~/composables/queries/useClientUser'

interface UrlOptions {
  organizationId?: string
  projectId?: string
  campaignId?: string
  mediaProjectId?: string
  questionnaireId?: string
}

export function useUserState() {
  const { user } = useUser()
  const localePath = useLocalePath()
  const route = useRoute()

  const accessError = createError({
    statusCode: 403,
    statusMessage: 'Access Denied',
  })

  const currentUser = computed(() => user.value)

  const isCurrentCampaign = () => {
    return currentUser.value?.currentCampaign?.id === route.params.campaignId
  }

  const isCurrentMediaProject = () => {
    return currentUser.value?.currentMediaProject?.id === route.params.mediaProjectId
  }

  const isCurrentOrganization = () => {
    return currentUser.value?.currentOrganization?.id === route.params.organizationId
  }

  const isClient = computed(() => !!currentUser.value?.currentCampaign)

  const isPublisher = computed(() => !!currentUser.value?.currentMediaProject)

  const getUserState = (): UrlOptions => {
    const { currentOrganization, currentCampaign, currentMediaProject, questionnaire } = currentUser.value || {}

    const organizationId = currentOrganization?.id

    const projectId = currentCampaign?.defaultProject?.id

    const campaignId = currentCampaign?.id

    const mediaProjectId = currentMediaProject?.id

    const questionnaireId = questionnaire?.id

    return { organizationId, projectId, campaignId, mediaProjectId, questionnaireId }
  }

  const buildUrl = (options?: UrlOptions) => {
    const state = getUserState()

    const organizationId = options?.organizationId || state.organizationId
    const projectId = options?.projectId || state.projectId
    const campaignId = options?.campaignId || state.campaignId
    const questionnaireId = state.questionnaireId
    if (!questionnaireId) {
      return localePath('survey')
    }

    if (!organizationId) throw accessError

    if (!campaignId && state.mediaProjectId) {
      return buildPublisherUrl()
    }

    return localePath({
      name: 'dashboard-organizationId-projectId-campaignId',
      params: { organizationId, projectId, campaignId },
    })
  }

  const buildPublisherUrl = (options?: UrlOptions) => {
    const state = getUserState()

    const organizationId = options?.organizationId || state.organizationId
    const mediaProjectId = options?.mediaProjectId || state.mediaProjectId

    if (!organizationId) throw accessError

    if (!mediaProjectId) {
      return localePath({
        name: 'dashboard-organizationId',
        params: { organizationId }
      })
    }

    return localePath({
      name: 'publisher-dashboard-organizationId-mediaProjectId',
      params: { organizationId, mediaProjectId },
    })
  }

  const redirectUser = () => {
    return navigateTo(buildUrl())
  }

  const redirectPublisher = () => {
    return navigateTo(buildPublisherUrl())
  }

  const toClientDashboard = async () => {
    const { currentOrganization, userProjects } = currentUser.value || {}
    const organizationId = currentOrganization?.id
    if (!organizationId) throw accessError

    const project = userProjects?.[0]?.defaultProject
    const projectId = project?.id

    const campaignId = project?.campaigns?.[0]?.id

    if (!projectId || !campaignId) {
      return navigateTo(localePath('survey'))
    }

    return navigateTo(buildUrl({ organizationId, projectId, campaignId }))
  }

  const toPublisherDashboard = async () => {
    const { currentOrganization, userMediaProjects } = currentUser.value || {}
    const organizationId = currentOrganization?.id
    if (!organizationId) throw accessError

    const mediaProjectId = userMediaProjects?.[0]?.mediaProject?.id
    if (!mediaProjectId) {
      return navigateTo(localePath('publisher-setup'))
    }

    return navigateTo(buildPublisherUrl({ organizationId, mediaProjectId }))
  }

  const { add } = useNotification()
  const { mutateAsync } = updateOrganization()
  const setCurrentOrganization = async (iri: string) => {
    if (!currentUser.value) return
    try {
      const data = await mutateAsync({
        params: { path: { id: currentUser.value.id } },
        body: {
          organization: iri,
          dashboardType: currentUser.value.currentCampaign ? 0 : 1
        },
      })
      add({ content: 'Organization has been successfully changed!' })
      return data
    }
    catch (e) {
      add({ content: 'Something went wrong when changing the organization' })
      console.error(e)
    }
  }

  return {
    getUserState,
    redirectUser,
    redirectPublisher,
    isCurrentOrganization,
    isCurrentCampaign,
    isCurrentMediaProject,
    isClient,
    isPublisher,
    buildUrl,
    buildPublisherUrl,
    toClientDashboard,
    toPublisherDashboard,
    setCurrentOrganization
  }
}
